// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-expertise-tsx": () => import("/opt/build/repo/src/pages/expertise.tsx" /* webpackChunkName: "component---src-pages-expertise-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-references-tsx": () => import("/opt/build/repo/src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-side-projects-tsx": () => import("/opt/build/repo/src/pages/side-projects.tsx" /* webpackChunkName: "component---src-pages-side-projects-tsx" */),
  "component---src-pages-team-tsx": () => import("/opt/build/repo/src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

